import React, { useState } from "react";
import "../css/QAOfficerManageCourses.css"; // Import the CSS file
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import baseUrl from "./Config/url";

class QAOfficerManageCourses extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    name: '',
    desc: '',
    users: [],
    // url: "http://sxk4031.uta.cloud/backend/deleteQualitycourse.php?q=",
    // approv_url: "http://sxk4031.uta.cloud/backend/approvequalitycourse.php?q=",
    // reject_url: "http://sxk4031.uta.cloud/backend/rejectqualitycourse.php?q=",
  };

  showPopup = () => {
    document.getElementById("popup").style.display = "block";
  };
  hidepop = () => {
    document.getElementById("popup").style.display = "none";
  };

  handlesave = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/addcourse`, {
          name: this.state.name,
          desc: this.state.desc,
         
        });
      alert(response.data);
      console.log(response.data);
      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (err) {
      console.log(err);
    }
  }
  
  accepted = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/caccept/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  reject = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/creject/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  delete = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/cdelete/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
    allstudents = (e) => {
      axios
      .get(`${baseUrl}/managecourse`)
      .then((res) => {
        this.setState({ users: res.data });
        
      });
   
    }

  componentDidMount() {
    this.allstudents();
  }
  render() {
    return (
      <>
        <Navbar />
        <div className="qa-officer-manage-courses">
          <h2>Manage/Review Courses</h2>

          {/* Button to show the Add Course form */}
          <button className="add-button" onClick={this.showPopup}>
            Add Course
          </button>

          {/* Table to display courses */}
          <div className="table1">
          <table className="courses-table">
            <thead>
              <tr>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((result) => {
                return (
                  <tr key={result.id}>
                    <td>{result.id}</td>
                    <td>{result.course_name}</td>
                    <td>{result.cour_desc}</td>
                    <td>
                      {result.status == 0
                        ? "Pending"
                        : result.status == 1
                        ? "Approve"
                        : "Reject"}
                    </td>

                    <td>
                      
                        <button className="approve-button" onClick={()=>{this.accepted(result.id)}}>Approve</button>
                      
                      
                        <button className="reject-button" onClick={()=>{this.reject(result.id)}}>Reject</button>
                      
                  
                        <button className="delete-button" onClick={() => { this.delete(result.id) }}>Delete</button>
                      
                    </td>
                  </tr>
                );
              })}
            </tbody>
            </table>
            </div>

          {/* Add Course Form Popup */}

          <div
            className="add-course-popup"
            id="popup"
            style={{ display: "none" }}
          >
            <h3>Add Course</h3>
            <form
              style={{ background: "rgb(176 214 255)" }}
              onSubmit={this.handlesave}
            >
              <div className="form-group">
                <label htmlFor="name">Course Name:</label>
                <input type="text" id="name" name="name" onChange={(e) => { this.setState({name: e.target.value })}}/>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea id="description" name="description" onChange={(e) => { this.setState({ desc: e.target.value })}}></textarea>
              </div>
              <button type="submit" className="add-button">
                Add Course
              </button>
              <button type="button" onClick={this.hidepop}>
                Cancel
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default QAOfficerManageCourses;
