// import React, { useState } from "react";
// import "../css/QADetails.css"; // Import the CSS file
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import axios from "axios";
// class QADetails extends React.Component {
//   constructor(props)
//   {
//     super(props)
//   }
//   state = {
//     id: "",
//     name: '',
//     email: '',
//     course: '',
//     perc: "" ,
//     stud_id: '',
//     edit:[],
//     users: [],
//     data:[]
//   //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
//   // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
//   }

  
//   allstudents = (e) => {
//     axios
//     .get("http://127.0.0.1:8000/api/allqa")
//       .then((res) => {
//         console.log(res.data);
//       this.setState({ data: res.data });
      
//     });
 
//   }
//   componentDidMount() {
//     this.allstudents();
   
     
//   }
//   handlesave = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await axios.post('http://127.0.0.1:8000/api/qasave', {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(response.data);
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
//       this.allstudents();
      
//     } catch (err) {
//       console.log(err);
//     }
//   }
//   handleedit = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await axios.post('http://127.0.0.1:8000/api/qaupdate/'+this.state.id, {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(response.data);
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})

//       this.setState({ edit: '' });
//       console.log(response.data,'response')
//       this.allstudents();
//       // window.location.href('/admin');
      
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   delete = async (id) =>{
//     const response = await axios.get('http://127.0.0.1:8000/api/qadelete/' + id
//     )
//     alert(response.data);

//       this.allstudents();
//   }
  
//   edit = async (id) => {
//     const response = await axios.get('http://127.0.0.1:8000/api/qaedit/' + id
//     )
//     console.log(response.data);
//     this.setState({id:response.data[0].id})
//     this.setState({edit : response.data});
//     this.setState({ name: response.data[0].name });
//     this.setState({ email: response.data[0].email });
//     this.setState({ stud_id: response.data[0].qa_id });
//     this.setState({ course: response.data[0].course });
//     this.setState({ perc: response.data[0].percentage });
//     this.setState({edit:response.data})
//   }

//  render(){
//   return (
//     <>
//       <Navbar />
//       <div className="qa-details">
//         <h2>QA Officers</h2>
//         <div className="table1">
//         <table className="qa-table">
//           <thead>
//             <tr>
//               <th>QA Officer Name</th>
//               <th>QA Officer ID</th>
//               <th>Email ID</th>
//               <th>Courses Assigned</th>
//               <th>Class Analytics: Pass %</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//           {this.state.data.map((qaOfficer) => {
//                 return (
//                   <tr key={qaOfficer.id}>
//                   <td>{qaOfficer.name}</td>
//                   <td>{qaOfficer.qa_id}</td>
//                   <td>{qaOfficer.email}</td>
//                   <td>{qaOfficer.course}</td>
//                   <td>{qaOfficer.percentage}</td>
//                   <td>
//                     <button
//                         className="edit-button"
//                         onClick={()=>{this.edit(qaOfficer.id)}}
                    
//                     >
//                       Edit
//                     </button>
//                     {/* <a href={this.state.url+qaOfficer.id}> */}
//                     <button
//                       className="delete-button"
//                       onClick={()=>{this.delete(qaOfficer.id)}}
                      
//                     >
//                       Delete
//                     </button>
//                     {/* </a> */}
//                   </td>
//                 </tr>
//                 )})}
//           </tbody>
//           </table>
//           </div>
//           {this.state.edit.length === 0 ?<>
//           <h2>Add QA Officer </h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handlesave}
//         >
//           <div className="form-group">
//             <label htmlFor="name">QA Officer Name:</label>
//             <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 onChange={(e) => { this.setState({ name: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">QA Officer ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//               name="email"
//               onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Assigned:</label>
//             <input
//               type="text"
//               id="courses"
//               name="courses"
//               onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//               name="passPercentage"
//               onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            Add
//           </button>
//         </form>
//         </>:<>
//         <h2>Edit QA Officer</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handleedit}
//         >
//              {this.state.edit.map((student) => {
//                 return (<>
//           <div className="form-group">
//             <label htmlFor="name">QA Officer Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               defaultValue={student.name}
//               onChange={(e) => { this.setState({ name: e.target.value }) }}
            
//                     />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">QA Officer ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               defaultValue={student.qa_id}
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
//                     />
//                   </div>
//                   {/* <input type="hidden" name="id" value={student.sid} /> */}
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//                       name="email"
//                       defaultValue={student.email}
//                       onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Teaching:</label>
//             <input
//               type="text"
//               id="courses"
//                       name="courses"
//                       defaultValue={student.course}
//                       onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//                       name="passPercentage"
//                       defaultValue={student.percentage}
//                       onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            update
//           </button>
//           </>)})}
//         </form></>}
//         {/* Form for adding/editing a QA officer */}
//         {/* <h2>Add/Edit QA Officer</h2>
//         <form action="http://sxk4031.uta.cloud/backend/add_quality.php"
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-qa-officer-form"
//         >
//           <div className="form-group">
//             <label htmlFor="name">QA Officer Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
            
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="qaOfficerId">QA Officer ID:</label>
//             <input
//               type="text"
//               id="qaOfficerId"
//               name="qaOfficerId"
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//               name="email"
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="coursesAssigned">Courses Assigned:</label>
//             <input
//               type="text"
//               id="coursesAssigned"
//               name="coursesAssigned"
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//               name="passPercentage"
             
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-qa-officer-button"
           
//           >
//             Add
//           </button>
//         </form> */}
//       </div>
//       <Footer />
//     </>
//   );
// };
// }
// export default QADetails;


import React, { useState } from "react";
import "../css/QADetails.css"; // Import the CSS file
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";

import baseUrl from './Config/url.js'
class QADetails extends React.Component {
  constructor(props)
  {
    super(props)
  }
  state = {
    id: "",
    name: '',
    email: '',
    course: '',
    perc: "" ,
    stud_id: '',
    edit:[],
    users: [],
    data:[]
  //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
  // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
  }

  
  allstudents = (e) => {
    axios
    .get(`${baseUrl}/allqa`)
      .then((res) => {
        console.log(res.data);
      this.setState({ data: res.data });
      
    });
 
  }
  componentDidMount() {
    this.allstudents();
   
     
  }
  handlesave = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/qasave`, {
          name: this.state.name,
          email: this.state.email,
          stud_id: this.state.stud_id,
          course: this.state.course,
          percentage: this.state.perc,
        });
      alert(response.data);
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      this.allstudents();
      
    } catch (err) {
      console.log(err);
    }
  }
  handleedit = async (e) => {
    e.preventDefault();

    console.log(this.state.name,
 
      this.state.email,
      
      this.state.stud_id,
      this.state.course,
      this.state.perc,
       )
    try {
        const response = await axios.post(`${baseUrl}/qaupdate/`+this.state.id, {
          name: this.state.name,
          email: this.state.email,
          stud_id: this.state.stud_id,
          course: this.state.course,
          percentage: this.state.perc,
        });
    
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})

      this.setState({ edit: '' });
      console.log(response.data,'response')
      this.allstudents();
      // window.location.href('/admin');
      
    } catch (err) {
      console.log(err);
    }
  }

  delete = async (id) =>{
    const response = await axios.get(`${baseUrl}/qadelete/` + id
    )
    alert(response.data);

      this.allstudents();
  }
  
  edit = async (id) => {
    const response = await axios.get(`${baseUrl}/qaedit/` + id
    )
    console.log(response.data);
    this.setState({id:response.data[0].id})
    this.setState({edit : response.data});
    this.setState({ name: response.data[0].qname });
    this.setState({ email: response.data[0].email });
    this.setState({ stud_id: response.data[0].qid });
    this.setState({ course: response.data[0].qass });
    this.setState({ perc: response.data[0].pass });
    this.setState({edit:response.data})
  }

 render(){
  return (
    <>
      <Navbar />
      <div className="qa-details">
        <h2>QA Officers</h2>
        <div className="table1">
        <table className="qa-table">
          <thead>
            <tr>
              <th>QA Officer Name</th>
              <th>QA Officer ID</th>
              <th>Email ID</th>
              <th>Courses Assigned</th>
              <th>Class Analytics: Pass %</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {this.state.data.map((qaOfficer) => {
                return (
                  <tr key={qaOfficer.id}>
                  <td>{qaOfficer.qname}</td>
                  <td>{qaOfficer.qid}</td>
                  <td>{qaOfficer.email}</td>
                  <td>{qaOfficer.qass}</td>
                  <td>{qaOfficer.pass}</td>
                  <td>
                    <button
                        className="edit-button"
                        onClick={()=>{this.edit(qaOfficer.id)}}
                    
                    >
                      Edit
                    </button>
                    {/* <a href={this.state.url+qaOfficer.id}> */}
                    <button
                      className="delete-button"
                      onClick={()=>{this.delete(qaOfficer.id)}}
                      
                    >
                      Delete
                    </button>
                    {/* </a> */}
                  </td>
                </tr>
                )})}
          </tbody>
          </table>
          </div>
          {this.state.edit.length === 0 ?<>
          <h2>Add QA Officer </h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" onSubmit={this.handlesave}
        >
          <div className="form-group">
            <label htmlFor="name">QA Officer Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => { this.setState({ name: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">QA Officer ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Assigned:</label>
            <input
              type="text"
              id="courses"
              name="courses"
              onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
              name="passPercentage"
              onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           Add
          </button>
        </form>
        </>:<>
        <h2>Edit QA Officer</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" onSubmit={this.handleedit}
        >
             {this.state.edit.map((student) => {
                return (<>
          <div className="form-group">
            <label htmlFor="name">QA Officer Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue={student.qname}
              onChange={(e) => { this.setState({ name: e.target.value }) }}
            
                    />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">QA Officer ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              defaultValue={student.qid}
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
                    />
                  </div>
                  {/* <input type="hidden" name="id" value={student.sid} /> */}
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
                      name="email"
                      defaultValue={student.email}
                      onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Teaching:</label>
            <input
              type="text"
              id="courses"
                      name="courses"
                      defaultValue={student.qass}
                      onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
                      name="passPercentage"
                      defaultValue={student.pass}
                      onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           update
          </button>
          </>)})}
        </form></>}
        {/* Form for adding/editing a QA officer */}
        {/* <h2>Add/Edit QA Officer</h2>
        <form action="http://sxk4031.uta.cloud/backend/add_quality.php"
          style={{ background: "rgb(176 214 255)" }}
          className="add-qa-officer-form"
        >
          <div className="form-group">
            <label htmlFor="name">QA Officer Name:</label>
            <input
              type="text"
              id="name"
              name="name"
            
            />
          </div>
          <div className="form-group">
            <label htmlFor="qaOfficerId">QA Officer ID:</label>
            <input
              type="text"
              id="qaOfficerId"
              name="qaOfficerId"
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
              name="email"
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="coursesAssigned">Courses Assigned:</label>
            <input
              type="text"
              id="coursesAssigned"
              name="coursesAssigned"
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
              name="passPercentage"
             
            />
          </div>
          <button
            type="submit"
            className="add-qa-officer-button"
           
          >
            Add
          </button>
        </form> */}
      </div>
      <Footer />
    </>
  );
};
}
export default QADetails;
