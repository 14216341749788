
import React, { useEffect,useState } from 'react'

import Navbar from '../Navbar'
import Footer from '../Footer'
import "../../../src/css/Feedback.css";
import axios from 'axios';
export default function Feedback() {
  const [feedBack, setFeedBack] = useState([]);
  
  const  allstudents =  async (e) => {
     const response = await axios.get('http://127.0.0.1:8000/api/feedback')
    
      
    console.log(response.data);
    setFeedBack(response);
 
  }
  
  useEffect(() => {
    allstudents();
  },[]);
  return (
    <>
    <Navbar />
    <div className='main' >
        <div className='manage'>{'Mannage Grade'}</div>
        <div className='table-res'>
          <table border='2' className='table' >
            <thead>
            <tr>
              <th   >Name</th>
              <th   >email</th>
              <th   >course</th>
              <th   >Feedback</th>
            </tr>
            </thead>
            <tbody>
              {feedBack.map((user, key) => {
                return (<>
                  <tr>
                    <td>{ user.name}</td>
                    <td>{ user.email}</td>
                    <td>{ user.course}</td>
                    <td>{ user.feedback}</td>
                  
                  </tr>
                  </>
                )
                
           })}
             
            
            </tbody>
            
          </table>
          </div>
    </div>
    <Footer />
    </>
  )
}
