// import React,{useState} from "react";
// import "../css/Admin.css"; // Import the CSS file
// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import axios from 'axios';

// export default function Admin(){
//   const [uname, setUname] = useState('');
//   const [uemail, setUemail] = useState('');
//   const [urole, setUrole] = useState('');
//   const handleadmin = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://127.0.0.1:8000/api/adminadd', {
//         name: uname,
//         email:uemail,
//         type: urole,
      
//       });
    
//       console.log(response.data);
//       alert(response.data);
//       setUname('');
//       setUemail('');
//       setUrole('');
//       // if (response.data == ) {
//       //   alert('Sign Up SuccessFul');
//       // }
      
//       // console.log(name, email, password, type);
    
//   } catch (error) {
//     console.error(error);
//   }

    
//   }
//   return (
//     <>
//       <Navbar />
//       <div className="admin-admin">
//         <div>
//           <h2>Admin</h2>
//           <div className="admin-cont">
//             <div className="row">
//               <div className="col-md-3">
//                 <div className="admin-investor-box">
//                   <h2>Student</h2>
//                   <div className="admin-flip-view">
//                     <Link to="/studentdetails">View Details</Link>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-3">
//                 <div className="admin-investor-box">
//                   <h2>Instructor</h2>
//                   <div className="admin-flip-view">
//                     <Link to="/instructordetails">View Details</Link>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-3">
//                 <div className="admin-investor-box">
//                   <h2>Quality Assurance Officer</h2>
//                   <div className="admin-flip-view">
//                     <Link to="/qadetails">View Details</Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div>
//           {/* <h2>Reports</h2>
//           <div className="reports-container">
//             <div className="profiles-container">
//               <h1>Student Profiles</h1>
//               <div className="user-info">
//                 <h5>User1</h5>
//                 <p>Name: John</p>
//                 <p>Email: abc@gmail.com</p>
//               </div>
//               <div className="user-info">
//                 <h5>User2</h5>
//                 <p>Name: John</p>
//                 <p>Email: abc@gmail.com</p>
//               </div>
//             </div>
//             <div className="profiles-container">
//               <h1>Teachers Profiles</h1>
//               <div className="user-info">
//                 <h5>User1</h5>
//                 <p>Name: John</p>
//                 <p>Email: abc@gmail.com</p>
//               </div>
//               <div className="user-info">
//                 <h5>User2</h5>
//                 <p>Name: John</p>
//                 <p>Email: abc@gmail.com</p>
//               </div>
//             </div>
//           </div> */}
//         </div>

//         <div>
//           <h2>Admin Access</h2>
//           <div className="container">
//             <form
//               onSubmit={handleadmin}
//               method="POST"
//               style={{ background: "rgb(176 214 255)" }}
//               id="user-management-form"
//             >
//               <label htmlFor="username">Username:</label>
//               <input
//                 type="text"
//                 id="username"
//                 name="username"
//                 placeholder="Enter username"
//                 onChange={(e) => { setUname(e.target.value) }}
//               />

//               <label htmlFor="email">Email:</label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 placeholder="Enter email"
//                 onChange={(e) => { setUemail( e.target.value) }}

//               />

//               <label htmlFor="role">Role:</label>
//               <select id="role" name="role" 
//                 onChange={(e) => {  setUrole( e.target.value) }}
              
//               >
                
//                 <option value="">Select One</option>
//                 <option value="user">User</option>
//                 <option value="admin">Admin</option>
//               </select>

//               <button type="submit">Edit User</button>
//             </form>

//             {/* <div id="user-list">
//               <h3>User List</h3>
//               <ul>
//                 <li>User 1 - john@example.com (User)</li>
//                 <li>User 2 - jane@example.com (Admin)</li>
//               </ul>
//             </div> */}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// // export default Admin;
import React, { useState } from "react";
import "../css/Admin.css"; // Import the CSS file
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";

import { useDarkMode } from "./../components/useDarkMode";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./../components/globalStyles";
import { lightTheme, darkTheme } from "./../components/theme/Themes";
import Toggle from "./../components/Toggler";
import baseUrl from "./Config/url";

export default function Admin() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [urole, setUrole] = useState("");

  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  console.log("theme", theme);

  const handleadmin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/adminadd`, {
        name: uname,
        email: uemail,
        type: urole,
      });

      console.log(response.data);
      alert(response.data);
      setUname("");
      setUemail("");
      setUrole("");
      // if (response.data == ) {
      //   alert('Sign Up SuccessFul');
      // }

      // console.log(name, email, password, type);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <ThemeProvider theme={themeMode}>
        <Navbar />
        <GlobalStyles />
        <Toggle theme={theme} toggleTheme={themeToggler} />

        <div className="admin-admin">
          <div>
            <h2>Admin</h2>
            <div className="admin-cont">
              <div className="row">
                <div className="col-md-3">
                  <div className="admin-investor-box">
                    <h2>Student</h2>
                    <div className="admin-flip-view">
                      <Link to="/studentdetails">View Details</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="admin-investor-box">
                    <h2>Instructor</h2>
                    <div className="admin-flip-view">
                      <Link to="/instructordetails">View Details</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="admin-investor-box">
                    <h2>Quality Assurance Officer</h2>
                    <div className="admin-flip-view">
                      <Link to="/qadetails">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <h2>Reports</h2>
          <div className="reports-container">
            <div className="profiles-container">
              <h1>Student Profiles</h1>
              <div className="user-info">
                <h5>User1</h5>
                <p>Name: John</p>
                <p>Email: abc@gmail.com</p>
              </div>
              <div className="user-info">
                <h5>User2</h5>
                <p>Name: John</p>
                <p>Email: abc@gmail.com</p>
              </div>
            </div>
            <div className="profiles-container">
              <h1>Teachers Profiles</h1>
              <div className="user-info">
                <h5>User1</h5>
                <p>Name: John</p>
                <p>Email: abc@gmail.com</p>
              </div>
              <div className="user-info">
                <h5>User2</h5>
                <p>Name: John</p>
                <p>Email: abc@gmail.com</p>
              </div>
            </div>
          </div> */}
          </div>

          <div>
            <h2>Admin Access</h2>
            <div className="container">
              <form
                onSubmit={handleadmin}
                method="POST"
                style={{ background: "rgb(176 214 255)" }}
                id="user-management-form"
              >
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter username"
                  onChange={(e) => {
                    setUname(e.target.value);
                  }}
                />

                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setUemail(e.target.value);
                  }}
                />

                <label htmlFor="role">Role:</label>
                <select
                  id="role"
                  name="role"
                  onChange={(e) => {
                    setUrole(e.target.value);
                  }}
                >
                  <option value="">Select One</option>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>

                <button type="submit">Edit User</button>
              </form>

              {/* <div id="user-list">
              <h3>User List</h3>
              <ul>
                <li>User 1 - john@example.com (User)</li>
                <li>User 2 - jane@example.com (Admin)</li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  );
}

// export default Admin;
