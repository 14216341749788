import React, { useState } from "react";
import "../css/QAOfficerMonitorReports.css"; // Import the CSS file
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
class QAOfficerMonitorReports extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    stud_name:'',
    stud_id:'',
    course:'',
    percentage:'',
    comment:'',
    users: [],
    url: "http://sxk4031.uta.cloud/backend/deletequality_student_report.php?q=",
  };
  showPopup = () => {
    document.getElementById("popup").style.display = "block";
  };
  hidepop = () => {
    document.getElementById("popup").style.display = "none";
  };

  handlesave = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('http://127.0.0.1:8000/api/addreport', {
          name: this.state.stud_name,
          stud_id: this.state.stud_id,
          percentage: this.state.percentage,
          course: this.state.course,
          comment: this.state.comment,
         
        });
      alert(response.data);
      console.log(response.data);
      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (err) {
      console.log(err);
    }
  }
  
  accepted = async (id) => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/raccept/'+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  reject = async (id) => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/rreject/'+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  delete = async (id) => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/rdelete/'+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
    allstudents = (e) => {
      axios
      .get("http://127.0.0.1:8000/api/getreport")
      .then((res) => {
        this.setState({ users: res.data });
        
      });
   
    }

  componentDidMount() {
    this.allstudents();
  }
  render() {
    return (
      <>
        <Navbar />
        <div className="qa-officer-monitor-reports">
          <h2>Monitor Student Reports</h2>

          {/* Button to show the Add Student Report form */}
          <button className="add-button" onClick={this.showPopup}>
            Add Student Report
          </button>

          <div className="table1">
          {/* Table to display student reports */}
          <table className="reports-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Student ID</th>
                <th>Course</th>
                <th>Pass Percentage</th>
                <th>Comments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((result) => {
                return (
                  <tr key={result.id}>
                    <td>{result.stud_name}</td>
                    <td>{result.stud_id}</td>
                    <td>{result.course}</td>
                    <td>{result.percentage}</td>
                    <td>{result.comment}</td>
                    <td>
                    
                        <button className="delete-button" onClick={() => { this.delete(result.id) }}>Delete</button>
                                        </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
</div>
          {/* Add Student Report Form Popup */}

          <div
            className="add-report-popup"
            id="popup"
            style={{ display: "none" }}
          >
            <h3>Add Student Report</h3>
            <form
              style={{ background: "rgb(176 214 255)" }}
              onSubmit={this.hadnlesave}
            >
              <div className="form-group">
                <label htmlFor="studentName">Student Name:</label>
                <input type="text" id="studentName" name="studentName" onChange={(e) => { this.setState({ stud_name: e.target.value })}} />
              </div>
              <div className="form-group">
                <label htmlFor="studentID">Student ID:</label>
                <input type="text" id="studentID" name="studentID" onChange={(e) => { this.setState({ stud_id: e.target.value })}} />
              </div>
              <div className="form-group">
                <label htmlFor="course">Course:</label>
                <input type="text" id="course" name="course" onChange={(e) => { this.setState({ course: e.target.value })}} />
              </div>
              <div className="form-group">
                <label htmlFor="passPercentage">Pass Percentage:</label>
                <input type="text" id="passPercentage" name="passPercentage" onChange={(e) => { this.setState({ percentage: e.target.value })}} />
              </div>
              <div className="form-group">
                <label htmlFor="comments">Comments:</label>
                <textarea id="comments" name="comments" onChange={(e) => { this.setState({ comment: e.target.value })}}></textarea>
              </div>
              <button type="submit" className="add-button">
                Add Student Report
              </button>
              <button type="button" onClick={this.hidepop}>
                Cancel
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default QAOfficerMonitorReports;
