import React, { useState } from "react";
import "../css/ProgramCoordinator.css";

import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import baseUrl from "./Config/url";

class ProgramCoordinator extends React.Component {
  constructor(props) {
    super(props);
  }
 

  state = {
      sname:'',
      sper:'',
      file:'',
        users:[],
        student:[],
        admin:[],
        qo:[],
        message:[],
        
        // delete_url:'http://sxk4031.uta.cloud/backend/deletprostudent.php?q=',
        // delete_url_pro:'http://sxk4031.uta.cloud/backend/delete_pro.php?q='
  }
      performance = async (id) => {
      try {
        const response = await axios.get(`${baseUrl}/getperformance`)
      // alert(response.data);
        console.log(response.data);
        this.setState({ student: response.data });
      // this.performance();
      
      
    } catch (err) {
      console.log(err);
    }
  }
  sreport = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/getadmin`)
    // alert(response.data);
      // console.log(response.data);
      this.setState({ admin: response.data });
    // this.performance();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  ireport = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/getins`)
    // alert(response.data);
      console.log(response.data);
      this.setState({ users: response.data });
    // this.performance();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  qreport = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/getqo`)
    alert(response.data);
      // console.log(response.data);
      this.setState({ qo: response.data });
    // this.performance();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  // handledelete = (id) => {
  //   alert(id);
  // }
  handleadd = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/addperformance`, {
        name: this.state.sname,
        per: this.state.sper
      })
    // alert(response.data);
      console.log(response.data);
      // this.setState({ student: response.data });
    this.performance();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  mesageHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/message`, {
        message: this.state.mess,
    
      })
    // alert(response.data);
      console.log(response.data);
      // this.setState({ student: response.data });

    
    
  } catch (err) {
    console.log(err);
  }
  }
   handledelete =  async (id) => {
    const response =  await axios.get(`${baseUrl}/deladmin/`+id)
    alert(response.data);
    this.ireport();
    this.sreport();
    this.qreport();
       

  
      
  }
  handledeletestu =  async (id) => {
    const response =  await axios.get(`${baseUrl}/delstu/`+id)
    alert(response.data);
    this.ireport();
    this.sreport();
    this.qreport();
       

  
      
  }
  handleedit = async (role) => {
    // e.preventDefault();
    // alert(role,this.state.file)
    const response = await axios.post(`${baseUrl}/addnew/`, {
      role: role,
      name: this.state.file,
    })

    alert(response.data);
    this.ireport();
    this.sreport();
    this.qreport();
       

  
      
   }
  
  componentDidMount() {
    this.ireport();
    this.sreport();
    this.qreport();
       

  
      this.performance();
    
    axios.get(`${baseUrl}/message`).then(res => {

        this.setState({ message: res.data });
        console.log(this.state.message)


    });


    }
    render()
    {
    return (
      <>
        <Navbar />
        <div className="program-coordinator">
          <h1>Program Coordinator Dashboard</h1>

          {/* Add new student */}
          <div>
            <form onSubmit={this.handleadd}>
              <h2>Add New Student</h2>
              <input type="text" placeholder="Name" onChange={(e) => { this.setState({sname: e.target.value })}} name="name" />
              <input type="text" placeholder="Performance" onChange={(e)=>{this.setState({sper: e.target.value})}} name="performance" />
              <button type="submit">Add Student</button>
            </form>
          </div>

          {/* Display student performance data */}
          <div className="student-performance">
            <h2>Student Performance Data</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Performance</th>
                  <th>Update Performance</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.student.map((result) => {
                  return (
                    <tr key={result.id}>
                      <td>{result.name}</td>
                      <td>{result.performance}</td>
                      <td>
                        <input type="text" value={result.performance} />
                      </td>
                      <td>
                        
                          {" "}
                        <button onClick={() => { this.handledeletestu(result.id) }}>Delete</button>
                        
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

            {/* File management section */}
            <div className="file-management">
                <h2>Shared Documents for Instructor</h2>
                <table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.users.map((result) => {
                            return (
                             <tr key={result.id}>
                                <td>{result.file_name}</td>
                                <td>
                                  <button
                               onClick={() => { this.handledelete(result.id) }}
                                  
                                  >Remove</button>
                                    
                                </td>
                            </tr>
                                  )
                                })}
                    </tbody>
                </table>
                <form onSubmit={() => { this.handleedit('instructor') }}>
                                <input type="hidden" name="role" value="instructor"/>
                <input style={{ marginTop: '20px' }} type="file" name="file" onChange={(e)=>{this.setState({file:e.target.filea[0]})}}/>
                <button type="submit">Add Now</button>
                </form>
            </div>

            <div className="file-management">
                <h2>Shared Documents for Admin</h2>
                <table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.admin.map((result) => {
                            return (
                             <tr key={result.id}>
                                <td>{result.file_name}</td>
                                <td>
                            
                                    <button onClick={() => { this.handledelete(result.id) }} >Remove</button>
                            
                                </td>
                            </tr>
                                  )
                                })}
                    </tbody>
                </table>
                <form onSubmit={() => { this.handleedit('admin') }}>
                                <input type="hidden" name="role" value="instructor"/>
                <input style={{ marginTop: '20px' }} type="file" name="file" onChange={(e)=>{this.setState({file:e.target.files[0]})}}/>
                <button type="submit">Add Now</button>
                </form>
            </div>

            <div className="file-management">
                <h2>Shared Documents for QA Officer</h2>
                <table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.qo.map((result) => {
                            return (
                             <tr key={result.id}>
                                <td>{result.file_name}</td>
                                <td>
                              
                                    <button onClick={() => { this.handledelete(result.id) }} >Remove</button>
                                                              </td>
                            </tr>
                                  )
                                })}  
                    </tbody>
                </table>
                <form onSubmit={() => { this.handleedit('officer') }}>
                                <input type="hidden" name="role" value="instructor"/>
                <input style={{ marginTop: '20px' }} type="file" name="file" onChange={(e)=>{this.setState({file:e.target.files[0]})}}/>
                <button type="submit">Add Now</button>
                </form>
            </div>

          <div className="program-coordinator">
            {/* ... */}
            <div className="student-support">
              <h1>Student Support</h1>
              <div className="student-inquiries">
                <p>Student Inquiries</p>
                <ul></ul>
              </div>
            </div>
          </div>

            {/* Chat interface */}
            <div className="chat">
                <h2>Chat with Instructors and Stakeholders</h2>
                <div className="chat-messages">
                    <ul>
                {this.state.message.map((result) => {
                            return (
                             <li style={{listStyle:'none'}}>{result.message}</li>
                                  )
                                })}  
                                </ul>
                </div>
                <div className="chat-input">
                    <form onSubmit={this.mesageHandler}>
                    <input
                        type="text"
                        placeholder="Type your message..."
                        name="message"
                        onChange={(e)=>{this.setState({mess: e.target.value})}}
                    />
                    <button type='submit'>Send</button>
                    </form>
                    
                </div>
            </div>
          </div>
     
        <Footer />
      </>
    );
  }
}
export default ProgramCoordinator;
