import React, { useState } from "react";
import { json, useNavigate, useRoutes } from "react-router-dom";

import {
  AdminAuthAction,
  ProgramCordinatorAuthAction,
  QOAuthAction,
  StuAuthAction,
  StudentProfileAction,
  TeachAuthAction,
  TeacherProfileAction,
} from "../components/Redux/Action";
import Store from "../components/Redux/Store";
import "../css/SignIn.css"; // Import the CSS file
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import baseUrl from "./Config/url";

export default function Password({route, navigation}) {
  const data = Store.getState();
  const navigate1 = useNavigate();
  const navigate2 = useNavigate(); 

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [state, setState] = useState({
    Email: "",
    Password: "",
    user: "",
  });

  // const handleName = (e) => {
  //   setName(e.value);
  // }
  const handlepassword = (event) => {
    // console.log(event/)
    setPassword(event.target.value);
  };

  const handleChangeEmail = (e) => {
    console.log("EMail", e.target.value);
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    console.log("password", e.target.value);
    setPassword(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    console.log("confirm password", e.target.value);
    setConfirmPassword(e.target.value);
  };
  const forgot = async (e) => {
    if (password == "") {
      alert("Please enter your new password!");
      e.preventDefault();
    } else if (password != confirmPassword) {
      alert("Password and confirm password is not matched!");
      e.preventDefault();
    } else {
      e.preventDefault();

      const userId = localStorage.getItem("user_id");
     console.log("User ID pasword", userId);
      // const { email, password } = e.target.elements;
      const body = {
        password: password,
        id:userId,
      };

     
      try {
        const resp = await axios.post(`${baseUrl}/newpassword`, body);
        console.log("Resp Password", resp);

        navigate1('/signin')
      } catch (error) {
        alert(error);
      }
    }
  };
  return (
    <>
      <Header />
      <div className="signin-container">
        <div className="signin-title">Change Password</div>
        <div className="signin-form">
          {/* //http://localhost/backend/login.php  php cde API ok server kaise run kr*/}

          {/* php -S localhost:8080 */}
          {/* es line se server start hoga php ka ook or kya krna hai esme
aap free hona haa


pkka kasam khao ksm se

ok

eska admin module krna hai

me show krte hu
*/}

          <form onSubmit={forgot} method="POST">
            <input
              type="password"
              // name="password"
              placeholder="Password"
              onChange={(e) => handleChangePassword(e)}
              value={password}
            />
            <input
              type="password"
              // name="password"
              placeholder="Confirm Password"
              onChange={(e) => handleChangeConfirmPassword(e)}
              value={confirmPassword}
            />

            <input type="submit" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
