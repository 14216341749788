import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import {
  AdminAction,
  ProgramCordinatorAction,
  QOAction,
  StudentAction,
  TeacherAction,
} from "../components/Redux/Action";
import "../css/SignUp.css"; // Import the CSS file
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import baseUrl from "./Config/url";
export default function SignUp() {
  const navigate1 = useNavigate();
  const [state, setState] = useState({
    name: "",
    Email: "",
    Password: "",
    user: "",
  });
  const [user, setUser] = useState("");

  console.log(state);

  const [studentArr, setStudentArr] = useState([]);
  const [teacherArr, setTeacherArr] = useState([]);
  const [adminArr, setAdminArr] = useState([]);
  const [qoArr, setQoArr] = useState([]);
  const [PCArr, setPCArr] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  const handleSubmit = async (e) => {
    if (!name) {
      alert("Please enter your name!");
      e.preventDefault();
    } else if (!email) {
      alert("Please enter your email!");
      e.preventDefault();
    }  else if (!password) {
      alert("Please enter your password!");
      e.preventDefault();
    } else if (!type) {
      alert("Please select your role!");
      e.preventDefault();
    } else {
      e.preventDefault();
      const body = {
        email: email,
        password: password,
        type: type,
        name: name,
      };
      try {
        const response = await axios.post(`${baseUrl}/register`, body);

        console.log("register Response", response.data.status);

        if (response.data.status == 200) {
          navigate1("/signin");
        } else {
          alert(response.data.message);
        }

        // console.log(name, email, password, type);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleType = (e) => {
    if (e.target.value === "Teacher") {
      return setType("instructor");
    } else if (e.target.value === "QO") {
      return setType("quality-officer");
    } else if (e.target.value === "ProgramCordinator") {
      return setType("program-coordinator");
    }
    setType(e.target.value);
  };
  console.log(studentArr);
  useEffect(() => {
    if (user === "Student") {
      StudentAction(studentArr);
    } else if (user === "Teacher") {
      TeacherAction(teacherArr);
    } else if (user === "Admin") {
      AdminAction(adminArr);
    } else if (user === "QO") {
      QOAction(qoArr);
    } else if (user === "ProgramCordinator") {
      ProgramCordinatorAction(PCArr);
    }
  }, [studentArr, teacherArr, adminArr, qoArr, PCArr]);

  function setTheData(e) {
    console.log(state.user);
    e.preventDefault();
    if (state.user === "Student") {
      setUser("Student");
      setStudentArr([...studentArr, state]);
      alert("Student Account Added");
    } else if (state.user === "Teacher") {
      setUser("Teacher");
      setTeacherArr([...teacherArr, state]);
      alert("Teacher Account Added");
    } else if (state.user === "Admin") {
      setUser("Admin");
      setAdminArr([...adminArr, state]);
      alert("Admin Account Added");
    } else if (state.user === "QO") {
      setUser("QO");
      setQoArr([...qoArr, state]);
      alert("Quality officer Account Added");
    } else if (state.user === "ProgramCordinator") {
      setUser("ProgramCordinator");
      setPCArr([...PCArr, state]);
      alert("Program Cordinator Account Added");
    }
  }

  const validate = () => {
    var password = document.frm.password.value;
    var name = document.frm.name.value;
    var email = document.frm.email.value;
    var password = document.frm.password.value;
    var profile = document.frm.profile.value;
    if (password.length < 6) {
      alert("atleast 6 characters required");

      return false;
    } else {
      alert("submited succesfully");
      window.location.href =
        "http://sxk4031.uta.cloud/backend/useradd.php?name=" +
        name +
        "&email=" +
        email +
        "&password=" +
        password +
        "&profile=" +
        profile;
    }
  };

  const handleNumber = (e) => {
    console.log(e);
    setPhoneNum(e);
  };
  return (
    <>
      <Header />
      <div className="signup-container">
        <div className="signup-title">Sign Up</div>
        <div className="signup-form">
          <form onSubmit={handleSubmit} method="POST">
            <input
              type="text"
              placeholder="Enter Name"
              name="name"
              onChange={handleName}
              className="input-style"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your Email here"
              onChange={handleEmail}
              className="input-style"
            />
            {/* <input
              type="text"
              name="number"
              id="num"
              placeholder="*111-111-1111"
              onChange={handleNumber}
              className="input-style"
            /> */}

            
            <input
              type="password"
              name="password"
              id="pass"
              placeholder="Enter Password"
              onChange={handlePassword}
              className="input-style"
            />
            <select name="profile" id="" onChange={handleType}>
              <option value="">Select the user type</option>
              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
              <option value="Admin">Admin</option>
              <option value="QO">Quality officer</option>
              <option value="ProgramCordinator">Program Coordinator</option>
            </select>
            <input
              type="submit"
              className="input-style"
              
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
