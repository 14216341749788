import React, { useState } from "react";
import "../css/QAOfficerManagePrograms.css"; // Import the CSS file
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import baseUrl from "./Config/url";
class QAOfficerManagePrograms extends React.Component {
  // const [users, usersSet] = React.useState([]);

  state = {
    name: '',
    desc:"",
    users: [],
    // url: "http://sxk4031.uta.cloud/backend/deletequalitymanagepro.php?q=",
    // approve_ulr: "http://sxk4031.uta.cloud/backend/approvequalitymanagepro.php?q=",
    // reject_url: "http://sxk4031.uta.cloud/backend/rejectqualitymanagepro.php?q=",
  };

  showPopup = () => {
    document.getElementById("popup").style.display = "block";
  };
  hidepop = () => {
    document.getElementById("popup").style.display = "none";
  };

  showPopup = () => {
    document.getElementById("popup").style.display = "block";
  };
  hidepop = () => {
    document.getElementById("popup").style.display = "none";
  };

  handlesave = async (e) => {
    e.preventDefault();

    alert(this.state.name,this.state.desc)
    try {
        const response = await axios.post(`${baseUrl}/addprogram`, {
          name: this.state.name,
          desc: this.state.desc,
         
        });
      alert(response.data);
      console.log(response.data);
      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (err) {
      console.log(err);
    }
  }
  
  accepted = async (id) => {
    alert(id)
    try {
      const response = await axios.post(`${baseUrl}/accept/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  reject = async (id) => {

    alert(id)
    try {
      const response = await axios.post(`${baseUrl}/reject/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
  delete = async (id) => {

    alert(id)
    try {
      const response = await axios.get(`${baseUrl}/delete/`+id, {
       
      });
    alert(response.data);
    console.log(response.data);
    this.allstudents();
    
    
  } catch (err) {
    console.log(err);
  }
  }
    allstudents = (e) => {
      axios
      .get(`${baseUrl}/manageprogram`)
      .then((res) => {
        this.setState({ users: res.data });
        
      });
   
    }

  componentDidMount() {
    this.allstudents();
  }

  render() {
    return (
      <>
        <Navbar />
        <div className="qa-officer-manage-programs">
          <h2>Manage/Review Programs</h2>
          <button className="add-button" onClick={this.showPopup}>
            Add Program
          </button>

          <div
            className="add-program-popup"
            id="popup"
            style={{ display: "none" }}
          >
            <h3>Add New Program</h3>
            <form
              style={{ background: "rgb(176 214 255)" }}
              onSubmit={this.handlesave}
            >
              <label htmlFor="name">Program Name:</label>
              <input type="text" id="name" name="name" onChange={(e) => { this.setState({name: e.target.value })}} />

              <label htmlFor="description">Description:</label>
              <textarea id="description" name="description" onChange={(e) => { this.setState({ desc: e.target.value })}}></textarea>

              <button type="submit">Add Program</button>
              <button type="button" onClick={this.hidepop}>
                Cancel
              </button>
            </form>
          </div>
        <div className="table1">
          <table className="programs-table">
            <thead>
              <tr>
                <th>Program ID</th>
                <th>Program Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((result) => {
                return (
                  <tr key={result.pid}>
                    <td>{result.pid}</td>
                    <td>{result.pro_name}</td>
                    <td>{result.descp}</td>
                    <td>
                      {result.status == 0
                        ? "Pending"
                        : result.status == 1
                        ? "Approve"
                        : "Reject"}
                    </td>

                    <td>
                    
                        <button className="approve-button" onClick={()=>{this.accepted(result.pid)}}>Approve</button>
                    
                      {/* <a href={this.state.reject_url + result.pid}> */}
                        <button className="reject-button" onClick={()=>{this.reject(result.pid)}}>Reject</button>
                      {/* </a> */}
                      {/* <a href={this.state.url + result.pid}> */}
                      <button className="delete-button" onClick={() => { this.delete(result.pid) }}>Delete</button>
                      {/* </a> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            </table>
            </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default QAOfficerManagePrograms;
