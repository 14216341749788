// import React, { useState } from "react";
// import "../css/StudentDetails.css"; // Import the CSS file
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import axios from "axios";
// import baseUrl from "./Config/url";
// class StudentDetails extends React.Component {
//   constructor(props)
//   {
//     super(props)
//   }
//   state = {
//     id: "",
//     name: '',
//     email: '',
//     course: '',
//     perc: "" ,
//     stud_id: '',
//     edit:[],
//     users: [],
//     data:[]
//   //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
//   // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
//   }

  
//   allstudents = (e) => {
//     axios
//     .get(`${baseUrl}/allstudents`)
//     .then((res) => {

//       // console.log('Res', res)
//       this.setState({ users: res.data });
      
//     });
 
//   }
//   componentDidMount() {
//     this.allstudents();
   
     

//   }
//   handlesave = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await axios.post(`${baseUrl}/studentsave`, {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(response.data);
//       this.allstudents();
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
//     } catch (err) {
//       // console.log(err);
//     }
//   }
//   handleedit = async (id) => {
//     // e.preventDefault();

//     alert(id)
//     try {
//         const response = await axios.post(`${baseUrl}/studentupdate/`+id, {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(JSON.stringify(response.data));
//       this.setState({ edit: '' });
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})

//       console.log(response.data,'response')
//       this.allstudents();
//       // window.location.href('/admin');
      
//     } catch (err) {
//       // console.log(err);
//     }
//   }

//   delete = async (id) =>{
//     const response = await axios.get(`${baseUrl}/studentdelete/` + id
//     )
//     alert(response.data);

//       this.allstudents();
//   }
  
//   edit = async (id) => {

//     alert(id)
//     const response = await axios.get(`${baseUrl}/studentedit/` + id
//     )
//     this.setState({id:response.data[0].id})
//     this.setState({edit : response.data});
//     this.setState({ name: response.data[0].name });
//     this.setState({ email: response.data[0].email });
//     this.setState({ stud_id: response.data[0].stud_id });
//     this.setState({ course: response.data[0].course });
//     this.setState({ perc: response.data[0].percentage });
//     this.setState({edit:response.data})
//   }
//  render()
//  {

//   return (
//     <>
//       <Navbar />
//       <div className="student-details">
//         <h2>Students</h2>
//         <div className="table1">
//         <table className="student-table">
//           <thead>
//             <tr>
//               <th>Student Name</th>
//               <th>Student ID</th>
//               <th>Email ID</th>
//               <th>Courses Enrolled</th>
//               <th>Class Analytics: Pass %</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//           {this.state.users.map((student) => {
//                 return (
//               <tr key={student.sid}>
//                 <td>{student.sname}</td>
//                 <td>{student.stud_id}</td>
//                 <td>{student.email}</td>
//                 <td>{student.course}</td>
//                 <td>{student.percentage}</td>
//                 <td>
//                   {/* <a href={this.state.editUrl+student.sid}> */}
//                   <button 
//                     className="edit-button"
//                     onClick={()=>{this.edit(student.sid)}}
//                   >
//                     Edit
//                   </button>
//                   {/* </a>     */}
//                  {/* <a href={this.state.url+student.id}> */}
//                   <button
//                     className="delete-button"
//                     onClick={()=>{this.delete(student.sid)}}
                        
                        
                        
//                   >
//                     Delete
//                   </button>
//                 {/* </a> */}
//                 </td>
//               </tr>
//             )})}	
//           </tbody>
//           </table>
//           </div>

//         {/* Form for adding/editing a student */}
//         {this.state.edit.length === 0 ?<>
//           <h2>Add Student</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handlesave}
//         >
//           <div className="form-group">
//             <label htmlFor="name">Student Name:</label>
//             <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 onChange={(e) => { this.setState({ name: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Student ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//               name="email"
//               onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Enrolled:</label>
//             <input
//               type="text"
//               id="courses"
//               name="courses"
//               onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//               name="passPercentage"
//               onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            Add
//           </button>
//         </form>
//         </>:<>
//         <h2>Edit Student</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handleedit} method="POST"
//         >
//              {this.state.edit.map((student) => {
//                 return (<>
//           <div className="form-group">
//             <label htmlFor="name">Student Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               defaultValue={student.name}
//               onChange={(e) => { this.setState({ name: e.target.value }) }}
            
//                     />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Student ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               defaultValue={student.stud_id}
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
//                     />
//                   </div>
//                   {/* <input type="hidden" name="id" value={student.sid} /> */}
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//                       name="email"
//                       defaultValue={student.email}
//                       onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Enrolled:</label>
//             <input
//               type="text"
//               id="courses"
//                       name="courses"
//                       defaultValue={student.course}
//                       onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//                       name="passPercentage"
//                       defaultValue={student.percentage}
//                       onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            update
//           </button>
//           </>)})}
//         </form></>}
      
//       </div>
//       <Footer />
//     </>
//   );
// };
// }
// export default StudentDetails;

import React, { useState } from "react";
import "../css/StudentDetails.css"; // Import the CSS file
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import baseUrl from "./Config/url";

class StudentDetails extends React.Component {
  constructor(props)
  {
    super(props)
  }
  state = {
    id: "",
    name: '',
    email: '',
    course: '',
    perc: "" ,
    stud_id: '',
    edit:[],
    users: [],
    data:[]
  //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
  // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
  }

  
  allstudents = (e) => {
    axios
    .get(`${baseUrl}/allstudents`)
    .then((res) => {
      this.setState({ users: res.data });
      
    });
 
  }
  componentDidMount() {
    this.allstudents();
  }
  handlesave = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/studentsave`, {
          name: this.state.name,
          email: this.state.email,
          stud_id: this.state.stud_id,
          course: this.state.course,
          percentage: this.state.perc,
        });
      
      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (err) {
      console.log(err);
    }
  }
  handleedit = async (e) => {
   
    e.preventDefault();
    const body = {
      name: this.state.name,
      email: this.state.email,
      stud_id: this.state.stud_id,
      course: this.state.course,
      percentage: this.state.perc,
    
    };

    // console.log('Body', body)
    try {
      const resp = await axios.put(`${baseUrl}/studentupdate`, body);

      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (error) {
      alert(error);
    }
  }

  delete = async (id) =>{

    alert(id)
    const response = await axios.get(`${baseUrl}/studentdelete/` + id
    )
    alert(response.data);

      this.allstudents();
  }
  
  edit = async (id) => {
    const response = await axios.get(`${baseUrl}/studentedit/` + id
    )
    this.setState({edit : response.data});
    this.setState({id:response.data[0].id})
    this.setState({ name: response.data[0].sname });
    this.setState({ email: response.data[0].email });
    this.setState({ stud_id: response.data[0].stud_id });
    this.setState({ course: response.data[0].course });
    this.setState({ perc: response.data[0].percentage });
    this.setState({edit:response.data})
  }
 render()
 {

  return (
    <>
      <Navbar />
      <div className="student-details">
        <h2>Students</h2>
        <div className="table1">
        <table className="student-table">
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Student ID</th>
              <th>Email ID</th>
              <th>Courses Enrolled</th>
              <th>Class Analytics: Pass %</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {this.state.users.map((student) => {
                return (
              <tr key={student.sid}>
                <td>{student.sname}</td>
                <td>{student.stud_id}</td>
                <td>{student.email}</td>
                <td>{student.course}</td>
                <td>{student.percentage}</td>
                <td>
                  {/* <a href={this.state.editUrl+student.sid}> */}
                  <button 
                    className="edit-button"
                    onClick={()=>{this.edit(student.sid)}}
                  >
                    Edit
                  </button>
                  {/* </a>     */}
                 {/* <a href={this.state.url+student.id}> */}
                  <button
                    className="delete-button"
                    onClick={()=>{this.delete(student.sid)}}
                        
                        
                        
                  >
                    Delete
                  </button>
                {/* </a> */}
                </td>
              </tr>
            )})}	
          </tbody>
          </table>
          </div>

        {/* Form for adding/editing a student */}
        {this.state.edit.length === 0 ?<>
          <h2>Add Student</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" onSubmit={this.handlesave}
        >
          <div className="form-group">
            <label htmlFor="name">Student Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => { this.setState({ name: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Student ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Enrolled:</label>
            <input
              type="text"
              id="courses"
              name="courses"
              onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
              name="passPercentage"
              onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           Add
          </button>
        </form>
        </>:<>
        <h2>Edit Student</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" 
          onSubmit={this.handleedit}
        >
             {this.state.edit.map((student) => {
                return (<>
          <div className="form-group">
            <label htmlFor="name">Student Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue={student.sname}
              onChange={(e) => { this.setState({ name: e.target.value }) }}
            
                    />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Student ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              defaultValue={student.stud_id}
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
                    />
                  </div>
                  {/* <input type="hidden" name="id" value={student.sid} /> */}
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
                      name="email"
                      defaultValue={student.email}
                      onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Enrolled:</label>
            <input
              type="text"
              id="courses"
                      name="courses"
                      defaultValue={student.course}
                      onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
                      name="passPercentage"
                      defaultValue={student.percentage}
                      onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
            
          >
           update
          </button>
          </>)})}
        </form></>}
      
      </div>
      <Footer />
    </>
  );
};
}
export default StudentDetails;
