import React, { useState } from "react";
import { json, useNavigate } from "react-router-dom";

import {
  AdminAuthAction,
  ProgramCordinatorAuthAction,
  QOAuthAction,
  StuAuthAction,
  StudentProfileAction,
  TeachAuthAction,
  TeacherProfileAction,
} from "../components/Redux/Action";
import Store from "../components/Redux/Store";
import "../css/SignIn.css"; // Import the CSS file
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import baseUrl from "./Config/url";

export default function ForgotPassword() {
  const data = Store.getState();
  const navigate1 = useNavigate();
  const navigate2 = useNavigate();
  console.log(data);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState({
    Email: "",
    Password: "",
    user: "",
  });

  // const handleName = (e) => {
  //   setName(e.value);
  // }
  const handlepassword = (event) => {
    // console.log(event/)
    setPassword(event.target.value);
  };

  const handleChangeEmail = (e) => {
    console.log("EMail", e.target.value);
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    console.log("password", e.target.value);
    setPassword(e.target.value);
  };
  const forgot = async (e) => {
    if (!email) {
      alert("Please enter your email!");
      e.preventDefault();
    } else {
      e.preventDefault();
      // const { email, password } = e.target.elements;
      const body = {
        email: email,
        password: password,
      };

      try {
        const resp = await axios.post(`${baseUrl}/forgetpassword`, body);

        if (resp.data.status == 200) {

          // console.log('USer id')
          // alert(resp.data.Array[0].userid)
          localStorage.setItem("user_id", resp.data.Array[0].userid);
          navigate1("/password");
        } else {
          alert(resp.data.message);
        }
      } catch (error) {
        alert(error);
      }
    }
  };
  return (
    <>
      <Header />
      <div className="signin-container">
        <div className="signin-title">Forgot Password</div>
        <div className="signin-form">
          {/* //http://localhost/backend/login.php  php cde API ok server kaise run kr*/}

          {/* php -S localhost:8080 */}
          {/* es line se server start hoga php ka ook or kya krna hai esme
aap free hona haa


pkka kasam khao ksm se

ok

eska admin module krna hai

me show krte hu
*/}

          <form onSubmit={forgot} method="POST">
            <input
              type="email"
              // name="email"
              placeholder="Enter your Email here"
              onChange={(e) => handleChangeEmail(e)}
              value={email}
            />

            <input type="submit" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
