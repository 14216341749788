// import React, { useState } from "react";
// import "../css/InstructorDetails.css"; // Import the CSS file
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import axios from "axios";

// class InstructorDetails extends React.Component {
//   constructor(props)
//   {
//     super(props)
//   }
//   state = {
//     id: "",
//     name: '',
//     email: '',
//     course: '',
//     perc: "" ,
//     stud_id: '',
//     edit:[],
//     users: [],
//     data:[]
//   //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
//   // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
//   }

  
//   allstudents = (e) => {
//     axios
//     .get("http://127.0.0.1:8000/api/allinstructor")
//       .then((res) => {
//         console.log(res.data);
//       this.setState({ data: res.data });
      
//     });
 
//   }
//   componentDidMount() {
//     this.allstudents();
   
     
//   }
//   handlesave = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await axios.post('http://127.0.0.1:8000/api/instsave', {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(response.data);
//       this.allstudents();
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
//     } catch (err) {
//       console.log(err);
//     }
//   }
//   handleedit = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await axios.post('http://127.0.0.1:8000/api/instupdate/'+this.state.id, {
//           name: this.state.name,
//           email: this.state.email,
//           stud_id: this.state.stud_id,
//           course: this.state.course,
//           percentage: this.state.perc,
//         });
//       alert(response.data);
//       this.setState({name:'',email:"",course:"",stud_id:'',perc:''})

//       this.setState({ edit: '' });
//       console.log(response.data,'response')
//       this.allstudents();
//       // window.location.href('/admin');
      
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   delete = async (id) =>{
//     const response = await axios.get('http://127.0.0.1:8000/api/instdelete/' + id
//     )
//     alert(response.data);

//       this.allstudents();
//   }
  
//   edit = async (id) => {
//     const response = await axios.get('http://127.0.0.1:8000/api/instedit/' + id
//     )
//     console.log(response.data);
//     this.setState({id:response.data[0].id})
//     this.setState({edit : response.data});
//     this.setState({ name: response.data[0].name });
//     this.setState({ email: response.data[0].email });
//     this.setState({ stud_id: response.data[0].ins_id });
//     this.setState({ course: response.data[0].course });
//     this.setState({ perc: response.data[0].percentage });
//     this.setState({edit:response.data})
//   }
// render()
// {
//   return (
//     <>
//       <Navbar />
//       <div className="instructor-details">
//         <h2>Instructors</h2>
//         <div className="table1">
//         <table className="instructor-table">
//           <thead>
//             <tr>
//               <th>Instructor Name</th>
//               <th>Instructor ID</th>
//               <th>Email ID</th>
//               <th>Courses Teach</th>
//               <th>Class Analytics: Pass %</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//           {this.state.data.map((instructor) => {
//                 return (
//           <tr key={instructor.id}>
//                 <td>{instructor.name}</td>
//                 <td>{instructor.ins_id}</td>
//                 <td>{instructor.email}</td>
//                 <td>{instructor.course}</td>
//                 <td>{instructor.percentage}</td>
//                 <td>
//                   <button
//                     className="edit-button"
//                     onClick={()=>{this.edit(instructor.id)}}
//                   >
//                     Edit
//                   </button>
                 
//                   <button
//                     className="delete-button"
//                     onClick={()=>{this.delete(instructor.id)}}
                   
//                   >
//                     Delete
//                   </button>
                 
//                 </td>
//               </tr>
//                   );
//                 })}
//           </tbody>
//           </table>
//           </div>
//         {/* "http://sxk4031.uta.cloud/backend/add_instructor.php" */}
//         {/* "http://sxk4031.uta.cloud/backend/update_instructor.php" */}
//         {/* Form for adding/editing an instructor */}
//         {/* {this.state.edit.lenght=== 0?<>
//           <h2>Add Instructor</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" action="http://sxk4031.uta.cloud/backend/add_instructor.php"
//         >
//           <div className="form-group">
//             <label htmlFor="name">Instructor Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Instructor ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//               name="email"
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Teaching:</label>
//             <input
//               type="text"
//               id="courses"
//               name="courses"
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//               name="passPercentage"
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            Add
//           </button>
//         </form>
//         </>:<>
//         <h2>Edit Instructor</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" action="http://sxk4031.uta.cloud/backend/update_instructor.php"
//         >
//              {this.state.edit.map((student) => {
//                 return (<>
//           <div className="form-group">
//             <label htmlFor="name">Instructor Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               defaultValue={student.ins_name}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Instructor ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="instructorId"
//               defaultValue={student.ins_id}
//             />
//                   </div>
//                   <input type="hidden" name="id" value={student.id} />
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//                       name="email"
//                       defaultValue={student.email}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Teaching:</label>
//             <input
//               type="text"
//               id="courses"
//                       name="coursesTeach"
//                       defaultValue={student.course}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//                       name="passPercentage"
//                       defaultValue={student.perc}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            update
//           </button>
//           </>)})}
//         </form></>} */}
//         {this.state.edit.length === 0 ?<>
//           <h2>Add Instructor </h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handlesave}
//         >
//           <div className="form-group">
//             <label htmlFor="name">Instructor Name:</label>
//             <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 onChange={(e) => { this.setState({ name: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Instructor ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//               name="email"
//               onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Teaching:</label>
//             <input
//               type="text"
//               id="courses"
//               name="courses"
//               onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//               name="passPercentage"
//               onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            Add
//           </button>
//         </form>
//         </>:<>
//         <h2>Edit Instructor</h2>
//         <form
//           style={{ background: "rgb(176 214 255)" }}
//           className="add-student-form" onSubmit={this.handleedit}
//         >
//              {this.state.edit.map((student) => {
//                 return (<>
//           <div className="form-group">
//             <label htmlFor="name">Instructor Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               defaultValue={student.name}
//               onChange={(e) => { this.setState({ name: e.target.value }) }}
            
//                     />
//           </div>
//           <div className="form-group">
//             <label htmlFor="studentId">Instructor ID:</label>
//             <input
//               type="text"
//               id="studentId"
//               name="studentId"
//               defaultValue={student.ins_id}
//               onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
//                     />
//                   </div>
//                   {/* <input type="hidden" name="id" value={student.sid} /> */}
//           <div className="form-group">
//             <label htmlFor="email">Email ID:</label>
//             <input
//               type="text"
//               id="email"
//                       name="email"
//                       defaultValue={student.email}
//                       onChange={(e) => { this.setState({ email: e.target.value }) }}
             
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="courses">Courses Teaching:</label>
//             <input
//               type="text"
//               id="courses"
//                       name="courses"
//                       defaultValue={student.course}
//                       onChange={(e) => { this.setState({ course: e.target.value }) }}
              
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
//             <input
//               type="text"
//               id="passPercentage"
//                       name="passPercentage"
//                       defaultValue={student.percentage}
//                       onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
//             />
//           </div>
//           <button
//             type="submit"
//             className="add-student-button"
           
//           >
//            update
//           </button>
//           </>)})}
//         </form></>}
        
//       </div>
//       <Footer />
//     </>
//   );
// };
// }
// export default InstructorDetails;

import React, { useState } from "react";
import "../css/InstructorDetails.css"; // Import the CSS file
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import baseUrl from "./Config/url";

class InstructorDetails extends React.Component {
  constructor(props)
  {
    super(props)
  }
  state = {
    id: "",
    name: '',
    email: '',
    course: '',
    perc: "" ,
    stud_id: '',
    edit:[],
    users: [],
    data:[]
  //   , url: "http://sxk4031.uta.cloud/backend/deleteStudent.php?q=",
  // editUrl:"http://sxk4031.uta.cloud/backend/edit_Student.php?q="
  }

  
  allstudents = (e) => {
    axios
    .get(`${baseUrl}/allinstructor`)
      .then((res) => {
        console.log(res.data);
      this.setState({ data: res.data });
      
    });
 
  }
  componentDidMount() {
    this.allstudents();
   
     
  }
  handlesave = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/instsave`, {
          name: this.state.name,
          email: this.state.email,
          stud_id: this.state.stud_id,
          course: this.state.course,
          percentage: this.state.perc,
        });
      alert(response.data);
      this.allstudents();
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})
      
    } catch (err) {
      console.log(err);
    }
  }
  handleedit = async (e) => {
    e.preventDefault();


    alert(this.state.id)
    try {
        const response = await axios.post(`${baseUrl}/instupdate/`+this.state.id, {
          name: this.state.name,
          email: this.state.email,
          stud_id: this.state.stud_id,
          course: this.state.course,
          percentage: this.state.perc,
        });
      alert(response.data);
      this.setState({name:'',email:"",course:"",stud_id:'',perc:''})

      this.setState({ edit: '' });
      console.log(response.data,'response')
      this.allstudents();
      // window.location.href('/admin');
      
    } catch (err) {
      console.log(err);
    }
  }

  delete = async (id) =>{
    const response = await axios.get(`${baseUrl}/instdelete/` + id
    )
    console.log(response.data);

      this.allstudents();
  }
  
  edit = async (id) => {
    const response = await axios.get(`${baseUrl}/instedit/` + id
    )
    console.log(response.data);
    this.setState({edit : response.data});
    this.setState({id:response.data[0].id})
    this.setState({ name: response.data[0].ins_name });
    this.setState({ email: response.data[0].email });
    this.setState({ stud_id: response.data[0].ins_id });
    this.setState({ course: response.data[0].course });
    this.setState({ perc: response.data[0].perc });
    this.setState({edit:response.data})
  }
render()
{
  return (
    <>
      <Navbar />
      <div className="instructor-details">
        <h2>Instructors</h2>
        <div className="table1">
        <table className="instructor-table">
          <thead>
            <tr>
              <th>Instructor Name</th>
              <th>Instructor ID</th>
              <th>Email ID</th>
              <th>Courses Teach</th>
              <th>Class Analytics: Pass %</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {this.state.data.map((instructor) => {
                return (
          <tr key={instructor.id}>
                <td>{instructor.ins_name}</td>
                <td>{instructor.ins_id}</td>
                <td>{instructor.email}</td>
                <td>{instructor.course}</td>
                <td>{instructor.perc}</td>
                <td>
                  <button
                    className="edit-button"
                    onClick={()=>{this.edit(instructor.id)}}
                  >
                    Edit
                  </button>
                 
                  <button
                    className="delete-button"
                    onClick={()=>{this.delete(instructor.id)}}
                   
                  >
                    Delete
                  </button>
                 
                </td>
              </tr>
                  );
                })}
          </tbody>
          </table>
          </div>
        {/* "http://sxk4031.uta.cloud/backend/add_instructor.php" */}
        {/* "http://sxk4031.uta.cloud/backend/update_instructor.php" */}
        {/* Form for adding/editing an instructor */}
        {/* {this.state.edit.lenght=== 0?<>
          <h2>Add Instructor</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" action="http://sxk4031.uta.cloud/backend/add_instructor.php"
        >
          <div className="form-group">
            <label htmlFor="name">Instructor Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Instructor ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
              name="email"
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Teaching:</label>
            <input
              type="text"
              id="courses"
              name="courses"
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
              name="passPercentage"
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           Add
          </button>
        </form>
        </>:<>
        <h2>Edit Instructor</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" action="http://sxk4031.uta.cloud/backend/update_instructor.php"
        >
             {this.state.edit.map((student) => {
                return (<>
          <div className="form-group">
            <label htmlFor="name">Instructor Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue={student.ins_name}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Instructor ID:</label>
            <input
              type="text"
              id="studentId"
              name="instructorId"
              defaultValue={student.ins_id}
            />
                  </div>
                  <input type="hidden" name="id" value={student.id} />
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
                      name="email"
                      defaultValue={student.email}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Teaching:</label>
            <input
              type="text"
              id="courses"
                      name="coursesTeach"
                      defaultValue={student.course}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
                      name="passPercentage"
                      defaultValue={student.perc}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           update
          </button>
          </>)})}
        </form></>} */}
        {this.state.edit.length === 0 ?<>
          <h2>Add Instructor </h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" onSubmit={this.handlesave}
        >
          <div className="form-group">
            <label htmlFor="name">Instructor Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => { this.setState({ name: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Instructor ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Teaching:</label>
            <input
              type="text"
              id="courses"
              name="courses"
              onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
              name="passPercentage"
              onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           Add
          </button>
        </form>
        </>:<>
        <h2>Edit Instructor</h2>
        <form
          style={{ background: "rgb(176 214 255)" }}
          className="add-student-form" onSubmit={this.handleedit}
        >
             {this.state.edit.map((student) => {
                return (<>
          <div className="form-group">
            <label htmlFor="name">Instructor Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue={student.ins_name}
              onChange={(e) => { this.setState({ name: e.target.value }) }}
            
                    />
          </div>
          <div className="form-group">
            <label htmlFor="studentId">Instructor ID:</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              defaultValue={student.ins_id}
              onChange={(e) => { this.setState({ stud_id: e.target.value }) }}
            
                    />
                  </div>
                  {/* <input type="hidden" name="id" value={student.sid} /> */}
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="text"
              id="email"
                      name="email"
                      defaultValue={student.email}
                      onChange={(e) => { this.setState({ email: e.target.value }) }}
             
            />
          </div>
          <div className="form-group">
            <label htmlFor="courses">Courses Teaching:</label>
            <input
              type="text"
              id="courses"
                      name="courses"
                      defaultValue={student.course}
                      onChange={(e) => { this.setState({ course: e.target.value }) }}
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="passPercentage">Class Analytics: Pass %:</label>
            <input
              type="text"
              id="passPercentage"
                      name="passPercentage"
                      defaultValue={student.perc}
                      onChange={(e) => { this.setState({ perc: e.target.value }) }}
            
            />
          </div>
          <button
            type="submit"
            className="add-student-button"
           
          >
           update
          </button>
          </>)})}
        </form></>}
        
      </div>
      <Footer />
    </>
  );
};
}
export default InstructorDetails;

